import { Avatar, Card, Icons, Stack, styled, Typography } from '@healthinal/ui';
import { createFileRoute, Navigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { useReadDataProjectSuspense } from '../../../../api/generated.ts';
import { DataProjectSupportLinks } from '../../../../data-projects/DataProjectSupportLinks.tsx';
import { PermissionsEditCard } from '../../../../data-projects/PermissionsEditCard.tsx';
import { RevokeGrantCard } from '../../../../data-projects/RevokeGrantCard.tsx';
import { useIsDataProjectGranted } from '../../../../data-projects/useIsDataProjectGranted.ts';

export const Route = createFileRoute('/_main/apps/$id/control-center')({
  component: DataProjectControlCenter,
});

function DataProjectControlCenter() {
  const { t } = useTranslation();
  const { id } = Route.useParams();
  const { data: dataProject } = useReadDataProjectSuspense(id);
  const isDataProjectGranted = useIsDataProjectGranted(id);

  if (!isDataProjectGranted) {
    return <Navigate from={Route.fullPath} to="../info" replace />;
  }

  return (
    <>
      <Grid>
        <Card size="lg">
          <Stack direction="row" alignItems="center" gap={2}>
            <Avatar color="success">
              <Icons.Check />
            </Avatar>
            <Typography level="body-sm" textColor="text.primary">
              {t('data-project-connected', { dataProject: dataProject.name })}
            </Typography>
          </Stack>
        </Card>

        <Card size="lg">
          <Typography level="title-sm">{t('support')}</Typography>
          <DataProjectSupportLinks dataProject={dataProject} />
        </Card>

        <PermissionsEditCard dataProject={dataProject} />

        <RevokeGrantCard dataProject={dataProject} />
      </Grid>
    </>
  );
}

const Grid = styled('div')({
  display: 'grid',
  gap: 24,
  gridTemplateColumns: '1fr 1fr',
  '> :nth-last-of-type(1), > :nth-last-of-type(2)': {
    alignSelf: 'start', // let the bottom two items have different heights (instead of stretching to the row height)
  },
});
