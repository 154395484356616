import { Alert, Box, Button, Icons, Stack } from '@healthinal/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadBlockedPidsSuspense } from '../../api/generated.ts';
import { useHealthcareProviderId } from '../../user/useHealthcareProviderId.ts';
import { PidBlockListTable } from './PidBlockListTable.tsx';
import { PidBlockModal } from './PidBlockModal.tsx';

export function PidBlockListContainer() {
  const { t } = useTranslation();

  const healthcareProviderId = useHealthcareProviderId();
  const { data: pidBlocks } = useReadBlockedPidsSuspense(healthcareProviderId);
  const isExistingPidBlock = (pid: string) => !!pidBlocks.find((pidBlock) => pidBlock.pid == pid);

  const [createOpen, setCreateOpen] = useState(false);

  return (
    <Stack gap={4}>
      {pidBlocks.length > 0 ? (
        <PidBlockListTable pidBlocks={pidBlocks} isExistingPidBlock={isExistingPidBlock} />
      ) : (
        <Alert color="neutral" variant={'outlined'} startDecorator={<Icons.InfoOutlined />}>
          {t('settings.block-list.empty')}
        </Alert>
      )}

      <Alert startDecorator={<Icons.PrivacyTipOutlined />}>{t('settings.block-list.pid-info')}</Alert>

      <Box>
        <Button variant="soft" size="sm" startDecorator={<Icons.Add />} onClick={() => setCreateOpen(true)}>
          {pidBlocks.length > 0 ? t('settings.block-list.block-pid') : t('settings.block-list.block-first-pid')}
        </Button>
      </Box>
      <PidBlockModal open={createOpen} close={() => setCreateOpen(false)} isExistingPidBlock={isExistingPidBlock} />
    </Stack>
  );
}
