import { createTheme } from '@healthinal/ui';

export const heurekaViolet = '#7B1EF2';
export const heurekaBlue = '#4C82EC';

export const contentWidth = 1024;

export const theme = createTheme({
  primary: {
    hue: 220,
  },
  neutral: {
    hue: 220,
    saturation: 40,
  },
});
