import { createFileRoute, Navigate } from '@tanstack/react-router';
import { useIsDataProjectGranted } from '../../../../data-projects/useIsDataProjectGranted.ts';

export const Route = createFileRoute('/_main/apps/$id/')({
  component: DataProjectIndex,
});

function DataProjectIndex() {
  const { id } = Route.useParams();
  const isDataProjectGranted = useIsDataProjectGranted(id);

  return <Navigate from={Route.fullPath} to={isDataProjectGranted ? 'control-center' : 'info'} replace />;
}
