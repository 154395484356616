import { Icons } from '@healthinal/ui';
import { SearchParamError } from '@tanstack/react-router';
import { AxiosError, HttpStatusCode, isAxiosError } from 'axios';
import { t } from 'i18next';
import { ReactNode } from 'react';
import { hardNavigate } from '../common/utils/hardNavigate.ts';

export interface ErrorInterpretation {
  title: string;
  description: string;
  details?: string;
  shouldBeReported: boolean;
  shouldBeHandledAtRoot?: boolean;
  actions?: ErrorAction[];
  icon?: ReactNode;
}

interface ErrorAction {
  label: string;
  onClick: (context: ErrorActionContext) => void;
}

interface ErrorActionContext {
  openSupportForm: () => void;
}

export function interpretError(error: unknown): ErrorInterpretation {
  if (error instanceof SearchParamError) {
    return interpretError((error as { cause?: unknown }).cause);
  }
  if (error instanceof ErrorWithInterpretation) {
    return error.interpretation;
  }
  if (isAxiosError(error)) {
    if (error.code === AxiosError.ERR_NETWORK) {
      return {
        title: t('error.network.title'),
        description: t('error.network.description'),
        shouldBeReported: false,
        actions: [reloadPageAction()],
      };
    }

    if (error.response?.status === HttpStatusCode.Unauthorized) {
      return {
        title: t('error.unauthenticated.title'),
        description: t('error.unauthenticated.description'),
        shouldBeReported: false,
        shouldBeHandledAtRoot: true,
      };
    }

    if (error.response?.status === HttpStatusCode.NotFound) {
      return notFoundErrorInterpretation();
    }

    if (
      error.response?.status &&
      [HttpStatusCode.BadGateway, HttpStatusCode.ServiceUnavailable, HttpStatusCode.GatewayTimeout].includes(
        error.response.status,
      )
    ) {
      return {
        title: t('error.network.server-unavailable.title'),
        description: t('error.network.server-unavailable.description'),
        shouldBeReported: false,
        shouldBeHandledAtRoot: true,
        actions: [reloadPageAction()],
      };
    }
  }

  return {
    title: t('error.unknown.title'),
    description: t('error.unknown.description'),
    details: error?.toString(),
    shouldBeReported: true,
    actions: [reloadPageAction(), supportAction()],
  };
}

export class ErrorWithInterpretation extends Error {
  constructor(public interpretation: ErrorInterpretation) {
    super(interpretation.title);
  }
}

export const supportAction = (): ErrorAction => ({
  label: t('contact-support'),
  onClick: ({ openSupportForm }) => openSupportForm(),
});

export const reloadPageAction = (): ErrorAction => ({
  label: t('reload-page'),
  onClick: () => window.location.reload(),
});

export const backToHomeAction = (): ErrorAction => ({
  label: t('error.not-found.action'),
  onClick: () => hardNavigate('/'),
});

export function notFoundErrorInterpretation(): ErrorInterpretation {
  return {
    title: t('error.not-found.title'),
    description: t('error.not-found.description'),
    shouldBeReported: false,
    shouldBeHandledAtRoot: false,
    actions: [backToHomeAction()],
    icon: <Icons.Search size="lg" />,
  };
}
