import { Button, Divider, Stack } from '@healthinal/ui';
import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadDataProjectSuspense } from '../../api/generated.ts';
import { AuthDataProjectPanel } from '../../auth/AuthDataProjectPanel.tsx';
import { RevokeGrantSuccess } from '../../data-projects/RevokeGrantSuccess.tsx';
import { AuthorizationColumn, LeftAuthorizationColumn } from '../authorization.tsx';
import { redirectToDataProject, validateRequiredSearchParams } from './-utils.ts';

export interface Search {
  redirect_uri: string;
  data_project_id: string;
}

export const Route = createFileRoute('/authorization/successful-revoke')({
  component: Revoke,
  validateSearch: (search: Record<string, unknown>): Search => {
    validateRequiredSearchParams(search, 'redirect_uri', 'data_project_id');
    return {
      redirect_uri: String(search.redirect_uri),
      data_project_id: String(search.data_project_id),
    };
  },
});

function Revoke() {
  const { t } = useTranslation();
  const { redirect_uri: redirectUri, data_project_id: dataProjectId } = Route.useSearch();

  const { data: dataProject } = useReadDataProjectSuspense(dataProjectId);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const redirectOptions = { dataProjectId: dataProjectId, redirectUri };

  return (
    <Stack direction="row" flex={1}>
      <LeftAuthorizationColumn>
        <AuthDataProjectPanel
          dataProject={dataProject}
          title={t('authorization.successful-revoke.title')}
          prompt={t('authorization.successful-revoke.prompt', {
            dataProject: dataProject.name,
          })}
          hint={t('authorization.successful-revoke.hint')}
          deactivateLogout
        />
      </LeftAuthorizationColumn>
      <Divider orientation="vertical" />
      <AuthorizationColumn>
        <RevokeGrantSuccess dataProject={dataProject} />
        <Button
          onClick={() => {
            setIsRedirecting(true);
            redirectToDataProject(redirectOptions);
          }}
          loading={isRedirecting}
          sx={{ marginTop: 'auto' }}>
          {t('continue-to-data-project')}
        </Button>
      </AuthorizationColumn>
    </Stack>
  );
}
