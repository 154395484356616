import { Alert, Button, Card, IconButton, Icons, Stack } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { PortalDataProjectWithScopesDto } from '../api/generated.ts';
import { interpretError } from '../errors/interpretError.tsx';
import { Permissions } from './Permissions.tsx';
import { useSetGrants } from './useSetGrants.ts';

interface PermissionsEditProps {
  dataProject: PortalDataProjectWithScopesDto;
}

export function PermissionsEditCard({ dataProject }: PermissionsEditProps) {
  const { t } = useTranslation();
  const {
    grantedScopeRequestIds,
    setGrantedScopeRequestIds,
    hasChanges,
    resetChanges,
    isSuccess,
    isError,
    error,
    isPending,
    mutate,
    reset,
  } = useSetGrants(dataProject, {
    mutation: {
      onSuccess: () => setTimeout(reset, 8000),
    },
  });

  return (
    <Card size="lg" sx={{ gap: 4 }}>
      <Permissions
        requests={dataProject.scopes}
        grantedIds={grantedScopeRequestIds}
        onGrantedIdsChange={setGrantedScopeRequestIds}
        disabled={isPending}
      />
      {isSuccess && (
        <Alert
          color="success"
          endDecorator={
            <IconButton onClick={reset} color="success">
              <Icons.Close />
            </IconButton>
          }>
          {t('permissions-saved')}
        </Alert>
      )}
      {isError && (
        <Alert
          color="danger"
          endDecorator={
            <IconButton onClick={reset} color="danger">
              <Icons.Close />
            </IconButton>
          }>
          {interpretError(error).description}
        </Alert>
      )}
      {hasChanges && (
        <Stack direction="row" gap={2}>
          <Button fullWidth variant="soft" color="neutral" onClick={resetChanges} disabled={isPending}>
            {t('reset')}
          </Button>
          <Button fullWidth onClick={mutate} loading={isPending}>
            {t('save')}
          </Button>
        </Stack>
      )}
    </Card>
  );
}
