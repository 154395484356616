import { Button, Divider, Stack } from '@healthinal/ui';
import { createFileRoute } from '@tanstack/react-router';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useReadDataProjectSuspense } from '../../api/generated.ts';
import { AuthDataProjectPanel } from '../../auth/AuthDataProjectPanel.tsx';
import { hardNavigate } from '../../common/utils/hardNavigate.ts';
import { AuthorizationSync } from '../../data-projects/AuthorizationSync.tsx';
import { Permissions } from '../../data-projects/Permissions.tsx';
import { useSetGrants } from '../../data-projects/useSetGrants.ts';
import { supportAction } from '../../errors/interpretError.tsx';
import { useHealthcareProviderId } from '../../user/useHealthcareProviderId.ts';
import { AuthorizationColumn, LeftAuthorizationColumn } from '../authorization.tsx';
import { redirectToDataProject, validateRequiredSearchParams } from './-utils.ts';

export interface Search {
  client_id: string;
  redirect_uri: string;
  state?: string;
}

export const Route = createFileRoute('/authorization/grant')({
  component: Grant,
  validateSearch: (search: Record<string, unknown>): Search => {
    validateRequiredSearchParams(search, 'client_id', 'redirect_uri');
    return {
      client_id: String(search.client_id),
      redirect_uri: String(search.redirect_uri),
      state: search.state ? String(search.state) : undefined,
    };
  },
});

function Grant() {
  const { t } = useTranslation();
  const search = Route.useSearch();
  const healthcareProviderId = useHealthcareProviderId();
  const { data: dataProject } = useReadDataProjectSuspense(search.client_id, {
    query: {
      interpretError: (error) => {
        if (isAxiosError(error) && (error.response?.status === 400 || error.response?.status === 404)) {
          return {
            title: t('authorization.error.invalid-request.title'),
            description: t('authorization.error.invalid-request.description'),
            details: `Invalid query parameter 'client_id' (${error.response.status})`,
            shouldBeReported: false,
            actions: [supportAction()],
          };
        }
        return undefined;
      },
    },
  });

  const { grantedScopeRequestIds, setGrantedScopeRequestIds, mutate, isPending, isSuccess } = useSetGrants(dataProject);

  const cancel = () =>
    redirectToDataProject({
      dataProjectId: dataProject.id,
      redirectUri: search.redirect_uri,
      error: 'access_denied',
      state: search.state,
    });

  return (
    <Stack direction="row" flex={1}>
      <LeftAuthorizationColumn>
        <AuthDataProjectPanel
          dataProject={dataProject}
          title={t('authorization.grant.title')}
          prompt={t('authorization.grant.prompt', { dataProject: dataProject.name })}
          hint={t('authorization.grant.hint')}
        />
      </LeftAuthorizationColumn>
      <Divider orientation="vertical" />
      <AuthorizationColumn>
        {isSuccess ? (
          <AuthorizationSync
            dataProject={dataProject}
            onFinish={() => redirectToAuthorizeEndpoint(healthcareProviderId, search)}
          />
        ) : (
          <>
            <Permissions
              requests={dataProject.scopes}
              grantedIds={grantedScopeRequestIds}
              onGrantedIdsChange={setGrantedScopeRequestIds}
              disabled={isPending}
            />
            <Stack direction="row" gap={2} mt="auto">
              <Button fullWidth onClick={cancel} disabled={isPending} variant="soft" color="neutral">
                {t('cancel')}
              </Button>
              <Button fullWidth onClick={mutate} loading={isPending}>
                {t('authorization.grant.action')}
              </Button>
            </Stack>
          </>
        )}
      </AuthorizationColumn>
    </Stack>
  );
}

function redirectToAuthorizeEndpoint(healthcareProviderId: string, { client_id, state, redirect_uri }: Search) {
  const redirect = new URL(
    `/portal-api/data-projects/${encodeURIComponent(client_id)}/authorize`,
    window.location.href,
  );
  redirect.searchParams.append('redirectUri', redirect_uri);
  redirect.searchParams.append('healthcareProviderId', healthcareProviderId);
  if (state) {
    redirect.searchParams.append('state', state);
  }
  hardNavigate(redirect);
}
