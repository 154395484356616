import { init, makeBrowserOfflineTransport, makeFetchTransport } from '@sentry/react';
import { environment } from '../common/utils/environment.ts';
import { interpretError } from './interpretError.tsx';

export function initSentry() {
  init({
    dsn: 'https://c87c7c7da0364d748ad35557aa18c683@sentry.healthinal.com/13',
    enabled: environment !== 'local',
    environment,
    release: import.meta.env.VITE_COMMIT_ID as string,
    transport: makeBrowserOfflineTransport(makeFetchTransport),
    beforeSend: (event, hint) => {
      const { shouldBeReported } = interpretError(hint.originalException);
      return shouldBeReported ? event : null;
    },
  });
}
