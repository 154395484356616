import { useQueryClient } from '@tanstack/react-query';
import { hardNavigate, HardNavigateOptions } from '../common/utils/hardNavigate.ts';

export const useLogout = () => {
  const queryClient = useQueryClient();

  return async (options?: HardNavigateOptions) => {
    await queryClient.invalidateQueries();
    hardNavigate('/portal-api/logout', options);
  };
};
