import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { ErrorView } from '../errors/ErrorView.tsx';
import { backToHomeAction, supportAction } from '../errors/interpretError.tsx';

export const Route = createFileRoute('/login-error')({
  component: LoginError,
});

function LoginError() {
  const { t } = useTranslation();

  return (
    <ErrorView
      title={t('login-error.title')}
      description={t('login-error.description')}
      shouldBeReported={false}
      actions={[backToHomeAction(), supportAction()]}
    />
  );
}
