import { useReadDataProjectsSuspense } from '../api/generated.ts';
import { useHealthcareProviderId } from '../user/useHealthcareProviderId.ts';

export function useIsDataProjectGranted(dataProjectId: string) {
  const healthcareProviderId = useHealthcareProviderId();
  const { data: grantedDataProjects } = useReadDataProjectsSuspense({
    grantedForHealthcareProviderId: healthcareProviderId,
  });
  return grantedDataProjects.some((dataProject) => dataProject.id === dataProjectId);
}
