import { Alert, Button, Icons, Stack, styled, Typography } from '@healthinal/ui';
import { useSupportForm } from '../common/components/SupportForm.tsx';
import { ErrorInterpretation } from './interpretError.tsx';

interface ErrorViewProps extends ErrorInterpretation {
  sentryId?: string;
}

export function ErrorView({ title, description, details, sentryId, shouldBeReported, actions, icon }: ErrorViewProps) {
  const openSupportForm = useSupportForm();

  return (
    <Stack maxWidth={480} alignItems="center" gap={2} margin="auto">
      {icon ?? <Icons.ErrorOutline color="danger" />}
      <Typography level="h3" textAlign="center">
        {title}
      </Typography>
      <Typography textAlign="center">{description}</Typography>
      {details && <Details>{details}</Details>}
      {shouldBeReported && sentryId && <Typography level="body-sm">Sentry ID: {sentryId}</Typography>}
      {actions && (
        <Stack direction="row" gap={2} marginTop={2}>
          {actions.map((action, index) => (
            <Button
              key={index}
              variant={index === 0 ? 'solid' : 'soft'}
              color="neutral"
              onClick={() => action.onClick({ openSupportForm })}>
              {action.label}
            </Button>
          ))}
        </Stack>
      )}
    </Stack>
  );
}

const Details = styled(Alert)`
  font-family: monospace;
  white-space: pre-line;
  width: 100%;
`;
