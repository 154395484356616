import { UseQueryOptions } from '@tanstack/react-query';
import { ErrorInterpretation, ErrorWithInterpretation } from '../errors/interpretError.tsx';

declare module '@tanstack/react-query' {
  interface UseQueryOptions {
    interpretError?: (error: unknown) => ErrorInterpretation | undefined;
  }
}

export function transformQueryOptions<T extends Pick<UseQueryOptions, 'queryFn' | 'interpretError'>>(options: T): T {
  const { interpretError, queryFn } = options;
  if (interpretError && typeof queryFn === 'function') {
    return {
      ...options,
      queryFn: async (...args) => {
        try {
          return await queryFn(...args);
        } catch (error) {
          const interpretation = interpretError(error);
          if (interpretation) {
            throw new ErrorWithInterpretation(interpretation);
          }
          throw error;
        }
      },
    };
  }
  return options;
}
