import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { HttpStatusCode, isAxiosError } from 'axios';
import { ReactNode } from 'react';
import { LoginScreen } from '../auth/LoginScreen.tsx';
import { ErrorView } from './ErrorView.tsx';
import { interpretError } from './interpretError.tsx';

interface ErrorBoundaryProps {
  children: ReactNode;
  isRoot?: boolean;
}

export function ErrorBoundary({ children, isRoot }: ErrorBoundaryProps) {
  return (
    <SentryErrorBoundary
      onError={(error) => {
        const { shouldBeHandledAtRoot } = interpretError(error);
        if (shouldBeHandledAtRoot && !isRoot) {
          throw error;
        }
      }}
      fallback={({ error, eventId }) => {
        if (isAxiosError(error) && error.response?.status === HttpStatusCode.Unauthorized) {
          return <LoginScreen />;
        }
        return <ErrorView {...interpretError(error)} sentryId={eventId} />;
      }}>
      {children}
    </SentryErrorBoundary>
  );
}
